.App {
  text-align: center;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

  /* Page content text color */
  color: #333;
  min-height: 100%;
  min-width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Saira Semi Condensed', sans-serif;
  font-size: 18px;
  line-height: 150%;
  
  /* Height at which footer collapses; 56px + 30px space between card and footer*/
  /* margin-bottom: 86px; */
}